<template>
  <KTCard
    :page="page"
    :isForm="true"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>Foto: <span class="text-danger">*</span></template>
        <KTDropzone
          dz_id="profilePhotoDz"
          :options="dz_options"
          v-model="images"
        />
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import MsgService from "@/core/services/msg.service";

export default {
  name: "ProfilePhotoForm",
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-id-card",
        title: "Alterar foto",
        description: `Arraste uma foto ou clique no campo abaixo para alterar
          sua foto pessoal. Se você estiver usando um aparelho celular ou tablet
          poderá tirar sua foto com a câmera do dispositivo. Para uma melhor
          qualidade utilize fotos em formato quadrado. Você pode configurar sua
          câmera para tirar fotos no formato quadrado.`
      },
      manual: {
        session: "profile_photo",
        tipo: 0
      },
      apiResource: "profile_photo",
      parentRoute: { name: "inicio" },
      dz_options: {},
      images: []
    };
  },
  methods: {
    onSubmit() {
      if (this.images.length) {
        this.ApiSubmitAdd({ images: this.images });
      } else {
        MsgService.show({
          title: "Erro no envio",
          description: `Nenhuma imagem foi selecionada.`,
          type: "error"
        });
      }
    },
    getPageData() {
      const requests = [this.Api.GetData()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.dz_options = res[R_GETDATA].dz_options ?? {};
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
    this.initManual(this.manual.session, this.manual.tipo);
  }
};
</script>
